<template>
  <b-card
    class="custom-border-top-1px"
  >
    <b-card-header class="pl-0 pt-0">
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >
        THÀNH VIÊN ĐỘI
      </b-badge>

      <b-button
        v-if="$ability().can('export', 'TeamReportMember')"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="sm"
        class="text-nowrap"
        @click="handleExportTeamReportMember"
      >
        <feather-icon
          class="text-white"
          icon="PlusIcon"
        />
        Xuất excel
      </b-button>
    </b-card-header>

    <div
      v-if="teamFund && teamFund.total"
      class="mb-1"
    >
      Tổng quỹ đội: <strong class="text-primary">{{ formatNumber(teamFund.total) }}  đ</strong>
    </div>
    <b-table
      ref="refUsersListTable"
      class="position-relative"
      responsive
      bordered
      striped
      primary-key="id"
      show-empty
      empty-text="Không có kết quả nào"
      :items="membersData"
      :fields="tableColumnsReportsPKH"
    >

      <!-- Column: stt -->
      <template #cell(stt)="data">
        <div>
          {{ data.index + 1 }}
        </div>
      </template>

      <!-- Column: avatar -->
      <template #cell(user_avatar)="data">
        <b-avatar
          :variant="`light-primary`"
          size="48"
          :src="data.item.user_avatar"
          :text="data.item.user_name.split(' ').pop()"
        />
      </template>

      <!-- Column: tên đội -->
      <template #cell(user_name)="data">
        <div
          class="text-primary cursor-pointer"
          @click="$emit('go-detail', data.item)"
        >
          {{ data.item['user_name'] }}
        </div>
      </template>

      <!-- Column: lương thu -->
      <template #cell(debt_salary)="data">
        <span>{{ formatNumber(data.item['total']) }}</span>
      </template>

      <!-- Column: Báo cáo kế hoạch thực hiện -->
      <template #cell(rating)="data">
        <div class="d-flex flex-column font-small-3">
          <div v-if="reportPlanMember = getDataReportPlanMember(data.item.user_id)">
            <div class="d-flex gap-1">
              <span class="text-primary">Số ngày công tối đa:</span>
              <span>{{ formatNumber(reportPlanMember.working_day_count) }}</span>
            </div>
            <div class="d-flex gap-1">
              <span class="text-primary">Tổng điểm: </span>
              <span>{{ formatNumber(reportPlanMember.total_rating_point) }}</span>
            </div>
            <div class="d-flex gap-1">
              <span class="text-primary">Điểm trung bình: </span>
              <span>{{ formatNumber(reportPlanMember.average_rating_point) }}</span>
            </div>
            <div class="d-flex gap-1">
              <span class="text-primary">Tổng ngày thực hiện: </span>
              <span>{{ formatNumber(reportPlanMember.total_rows) }}</span>
            </div>
          </div>
        </div>
      </template>

      <!-- Column: chi tiết -->
      <template #cell(actions)="data">
        <div class="text-right">
          <b-button
            size="sm"
            variant="primary"
            @click="$emit('go-detail', data.item)"
          >Chi tiết
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BTable, VBTooltip, BBadge, BButton, BAvatar, BForm, BCardBody, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import ability from '@/libs/acl/ability'

export default {
  components: {
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BForm,
    BCardHeader,
    BCard,
    BTable,
    BBadge,
    BButton,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    reportMembers: {
      type: Array,
      default: () => [],
    },
    reportPlan: {
      type: Array,
      default: () => [],
    },
    teamFund: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    membersData() {
      const userData = getUserData()
      if (!userData.job_title_code || userData.job_title_code === 'TDPC') {
        return this.reportMembers
      }
      return this.reportMembers.filter(item => item.user_id === userData.id) || []
    },
  },
  methods: {
    handleExportTeamReportMember() {
      this.$emit('export-team-report-member')
    },
    // eslint-disable-next-line camelcase
    getDataReportPlanMember(user_id) {
      // eslint-disable-next-line camelcase
      return this.reportPlan.find(item => item.user_id === user_id)
    },
  },
  setup() {
    const tableColumnsReportsPKH = [
      {
        label: 'STT',
        key: 'stt',
      },
      {
        label: 'Avatar',
        key: 'user_avatar',
      },
      {
        label: 'Tên',
        key: 'user_name',
        tdClass: 'minw-150',
      },
      {
        label: 'Lương thu',
        key: 'debt_salary',
      },
      {
        label: 'Đánh giá',
        key: 'rating',
      },
      {
        label: '',
        key: 'actions',
      },
    ]
    return {
      tableColumnsReportsPKH,
    }
  },
}
</script>
